// import uuid from 'uuid/v4';

const API_URL = process.env.GATSBY_API_URL;
const ERP_API_URL = process.env.GATSBY_ERP_API;
const MARKETPLACE_API_ULR = process.env.GATSBY_MARKETPLACE_API;
const MARKETPLACE_DEV_API_ULR = `https://marketplaceapi.gildform.com`;

export const safeGetAuthFromState = (state) => {
  if (!!state.login.auth) {
    return state.login.auth.auth.accessToken;
  }
  return null;
};

export const apiFetch = async (endpoint, options, apiToken) => {
  options = options === void 0 ? {} : options;
  if (apiToken !== void 0 && apiToken !== null) {
    if (options.headers === void 0) {
      options.headers = {};
    }
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${apiToken}`,
    };
  }
  const doFetch = async (retryNum) => {
    const response = await fetch(`${API_URL}${endpoint}`, options, apiToken);
    if (!response.ok && retryNum > 0) {
      return doFetch(retryNum - 1);
    }
    return response;
  };

  const response = await doFetch(1);
  if (!response.ok) {
    const e = new Error(
      `API responded with ${response.status}: ${response.statusText}`
    );
    e.status = response.status;
    e.response = response;
    throw e;
  }
  return response.json();
};

export const createCart = (apiToken) =>
  apiFetch('/carts', { method: 'POST' }, apiToken);

export const uploadFile = async ({ id, file }, apiToken) => {
  let formData = new FormData();

  formData.append('id', id);
  formData.append('file', file);

  const response = await apiFetch(
    '/models/presigned',
    {
      method: 'POST',
      body: formData,
    },
    apiToken
  );

  let { url, fields, bucketKey } = response;

  formData = new FormData();

  for (let [key, value] of Object.entries(fields)) {
    formData.append(key, value);
  }

  formData.append('key', bucketKey);
  formData.append('file', file);

  const uploadOptions = {
    method: 'POST',
    mode: 'cors',
    body: formData,
  };

  const uploadResponse = await fetch(url, uploadOptions);

  if (!uploadResponse.ok) {
    throw new Error(
      `Failed to upload mesh to s3: responded with ${response.status}: ${response.statusText
      } `
    );
  }

  return { url, fields, bucketKey };
};

export const deleteFromCart = (cartID, itemID, apiToken) =>
  apiFetch(
    `/carts/${cartID}/items/${itemID}`,
    {
      method: 'DELETE',
    },
    apiToken
  );

export const addToCart = (cartId, cartData, apiToken) => {
  const body = JSON.stringify({
    ...cartData,
    isCheckedOut: false,
  });
  return apiFetch(
    `/carts/${cartId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    },
    apiToken
  );
};

export const refreshCart = (cartID, apiToken) => {
  return apiFetch(`/carts/${cartID}`, { method: 'GET' }, apiToken);
};

export const checkoutCart = async ({
  customer,
  tax,
  notes,
  shippingMethod,
  address,
  billing_address,
  shipTo,
  orderItems,
  apiToken,
  cartId,
  defaultCard,
  newCard,
  shippoOptions,
  shipping,
  matchedCode,
}) => {
  // const formData = new FormData();
  // formData.append('customer', customer);
  // formData.append('tax', tax);
  // formData.append('notes', notes);
  // formData.append('shippingMethod', shippingMethod);
  // formData.append('address', address);
  // formData.append('orderItems', orderItems);
  // for (const orderItem of orderItems) {
  //   for (const image of orderItem.laserImage) {
  //     formData.append('images', image);
  //   }
  // }
  // formData.append('apiToken', apiToken);
  // formData.append('cartId', cartId);
  // formData.append('defaultCard', defaultCard);
  // formData.append('newCard', newCard);
  // formData.append('shippoOptions', shippoOptions);
  // formData.append('shipping', shipping);
  const res = await apiFetch('/orders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify({
      customer,
      tax,
      notes,
      shippingMethod,
      address,
      billing_address,
      shipTo,
      orderItems,
      cartId,
      defaultCard,
      newCard,
      shippoOptions,
      shipping,
      matchedCode,
    }),
    // formData,
  });
  console.log('res ne api :>> ', res);
  return res;
};

export const checkoutOfferCart = async (data) => {
  const res = apiFetch('/offers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.apiToken}`,
    },
    body: JSON.stringify(data),
  });
  return res;
};

export const getPrice = (price, authToken) => {
  return apiFetch('/pricing', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(price),
  });
};

export const getAuth0Token = (authToken) => {
  return apiFetch('/users-token', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const getErpMembershipContent = async () => {
  const res = await fetch(`${ERP_API_URL}/memberships`, {
    headers: {
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    }
  });
  const data = await res.json();
  return data;
};

export const uploadLaserEngravingImages = async (images, id, dataTo) => {
  const formData = new FormData();
  for (const image of images) {
    if (image.generated) {
      formData.append('images', image.img.img);
      formData.append('generated', image.generated);
      formData.append('image_text', image.image_text);
      formData.append('email', dataTo.email);
      formData.append('name', dataTo.name);
      formData.append('userId', dataTo.userId);
      formData.append('type', dataTo.type);
      formData.append('test', dataTo.test);
    } else {
      formData.append('images', image);
    }
  }
  formData.append('orderItemId', id);

  const resData = await fetch(`${ERP_API_URL}/s3/upload-laser-engraving`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: formData,
    headers: {
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    }
  });
  const data = await resData.json();
  console.log('data  uploadLaserEngravingImages:>> ', data);
  const genrt = images.find((ig) => ig.generated);

  if (genrt) {
    const dataToSend = {
      image_text: genrt.image_text,
      image: data ? (data.data[0] ? data.data[0] : '') : null,
      email: dataTo.email,
      name: dataTo.name,
      type: dataTo.type,
      userId: dataTo.userId,
      orderItem: id,
    };
    const rsd = await fetch(`${MARKETPLACE_API_ULR}/image-catalog`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    });
    const dd = await rsd.json();
    console.log('dd :>> ', dd);
  }
  return data;
};

export const uploadGildformStoreProductImages = async (image) => {
  const formData = new FormData();
  formData.append('image', image);
  const resData = await fetch(
    `${ERP_API_URL}/s3/upload-gildform-store-product-image`,
    {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
      }
    }
  );
  const data = await resData.json();

  return data;
};
export const getTransactionsByCustomerId = async (id) => {
  const res = await fetch(`${ERP_API_URL}/transaction/customer/${id}`, {
    headers: {
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    }
  });
  const data = await res.json();
  return data;
};

export const sendEmailPasswordReset = async (data) => {
  const res = await fetch(`${ERP_API_URL}/mails/password-reset`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    },
    body: JSON.stringify(data),
  });
  return res;
};

export const getGildformStatuses = async (id) => {
  const res = await fetch(`${ERP_API_URL}/setting-groups/${id}`, {
    headers: {
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    }
  });
  const data = await res.json();
  return data;
};

export const getUserPermissions = async (id) => {
  const res = await fetch(`${ERP_API_URL}/setting-groups/${id}`, {
    headers: {
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    }
  });
  const data = await res.json();
  return data;
};

export const getUserOffers = async (id) => {
  const res = await fetch(`${ERP_API_URL}/setting-groups/${id}`, {
    headers: {
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    }
  });
  const data = await res.json();
  return data;
};

export const getProductVariants = async (id) => {
  const res = await fetch(`${ERP_API_URL}/items?type=p`, {
    headers: {
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    }
  });
  const data = await res.json();
  return data;
};

export const getSettingsById = async (id) => {
  const res = await fetch(`${ERP_API_URL}/settings/${id}`, {
    headers: {
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    }
  });
  const data = await res.json();
  return data;
};

export const getMarketplaceUser = async (userId) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/project/gildformUser/${userId}`
  );
  const data = await res.json();
  return data;
};

export const getMarketplaceUserProjectItems = async (userId) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/project-items/user/auth0/${userId}`
  );
  const data = await res.json();
  return data;
};

export const getPresignedUrlMarketplace = async (folder, fileName) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/project/item/${fileName}/meshes/${folder}`
  );
  const data = await res.json();
  return data;
};

export const openConnectAccount = async (id) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/stripe/gildform/accLink/${id}`
  );
  const data = await res.json();
  return data;
};

export const getConnectAccount = async (id) => {
  const res = await fetch(`${MARKETPLACE_API_ULR}/stripe/connect/${id}`);
  return res;
};

export const getConnectAccountLink = async (id) => {
  const res = await fetch(`${MARKETPLACE_API_ULR}/stripe/account/link/${id}`);
  return res;
};

export const generateAiImages = async (data) => {
  const res = await fetch(
    `${MARKETPLACE_DEV_API_ULR}/project-items/ai/txt-image`,
    {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  );
  return res;
};

export const brandForgeTextComplete = async (data) => {
  const res = await fetch(`${MARKETPLACE_API_ULR}/brandForge/completion`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return res;
};

export const getUserBrandForgeMessages = async (userId) => {
  const res = await fetch(`${MARKETPLACE_API_ULR}/brandForge/user/${userId}`);
  return res;
};

export const getCurrentMonthUserBrandForgeMessages = async (userId) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/brandForge/user/current/${userId}`
  );
  return res;
};

export const getActiveUserBrandForgeMessages = async (userId) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/brandForge/active/user/${userId}`
  );
  return res;
};

export const updateBrandForgeChatName = async (chatId, data, token) => {
  const chatNameUpdate = await fetch(
    `${MARKETPLACE_API_ULR}/brandForge/chat-name/edit/${chatId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  return chatNameUpdate;
};

export const deleteBrandForgeChat = async (chatId, token) => {
  const deleted = await fetch(
    `${MARKETPLACE_API_ULR}/brandForge/delete/chat/${chatId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return deleted;
};

export const getShippingOptions = (apiToken) =>
  apiFetch(`/shipping-methods`, undefined, apiToken);

export const getStatesByCountry = (id, apiToken) =>
  apiFetch(`/countries/${id}/states`, undefined, apiToken);

export const getAllAssyemblyOptions = (itemId) =>
  apiFetch(`/items/subitems/${itemId}`);

export const uploadItemImage = (itemId, image) => {
  const formData = new FormData();

  formData.append('image', image);

  return apiFetch(`/screenshot`, {
    method: 'POST',
    body: formData,
  });
};

export const getStatus = (jobOrderID, apiToken) => {
  return apiFetch(`/orders/${jobOrderID}`, undefined, apiToken);
};

export const getUser = (userID, apiToken) => {
  return apiFetch(`/users/${userID}`, undefined, apiToken);
};

export const getOrderById = (id, apiToken) => {
  return apiFetch(`/orders/${id}`, undefined, apiToken);
};

export const getOrdersByUserId = (userID, apiToken) => {
  return apiFetch(`/users/${userID}/orders`, undefined, apiToken);
};

export const getAllCountries = (apiToken) =>
  apiFetch(`/countries`, undefined, apiToken);

export const getAllQuestions = (apiToken) =>
  apiFetch(`/questions/onboard`, undefined, apiToken);

export const getAnswer = (id, apiToken) =>
  apiFetch(`/answers/${id}`, undefined, apiToken);

export const getAllAnswers = (apiToken) =>
  apiFetch(`/answers`, undefined, apiToken);

export const getAnswersByQuestion = (id, apiToken) =>
  apiFetch(`/answers/question/${id}`, undefined, apiToken);

export const getAllRoadmaps = (apiToken) =>
  apiFetch(`/roadmap`, undefined, apiToken);

export const getAll3DModels = (apiToken) =>
  apiFetch(`/modelthreed`, undefined, apiToken);

export const getPresignedUrl = (folder, apiToken) =>
  apiFetch(`/meshes/${folder}`, undefined, apiToken);

// export const getFonts = async () => {
//   const res = await fetch(`${ERP_API_URL}/settings/fonts`);
//   const data = await res.json();
//   return data;
// };

export const createRoadmapActivity = (data, apiToken) => {
  const milestoneUpdate = apiFetch(`/roadmap-activities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(data),
  });
  return milestoneUpdate;
};

export const getShippmentRates = async (data, token) => {
  const rates = await apiFetch(`/addShipment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  return rates;
};

export const getPromotionCodes = async (token) => {
  const res = await fetch(`${ERP_API_URL}/manufacturers/promo/codes`, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer gildform_access_token_3PMK4NJmRbKhdb53HzvRkq3iXevzFKfW'
    },
  });
  const data = await res.json();
  return data;
};

export const createUser = (auth0User, token) =>
  apiFetch(`/users/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(auth0User),
  });

export const createSubUser = (auth0User, token) =>
  apiFetch(`/users-sub/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(auth0User),
  });

export const getMembershipPlans = () => apiFetch(`/new-products`);

export const updateUser = (user, token) => {
  const userUpdate = apiFetch(`/users/${user.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  });
  return userUpdate;
};

export const updateMembership = async (userID, newMembership, token) => {
  const membershipUpdate = await apiFetch(`/users/${userID}/subscription`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newMembership),
  });

  return membershipUpdate;
};

export const updateBilling = (userID, stripeToken, authToken) =>
  apiFetch(`/users/${userID}/billing`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ token: stripeToken }),
  });

export const addNewCreditCard = async (userID, stripeToken, authToken) => {
  return await apiFetch(`/users/${userID}/source`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ stripeToken: stripeToken }),
  });
};

export const getUsersCarts = async (userID, authToken) => {
  userID = encodeURI(userID);

  const carts = await apiFetch(
    `/carts/users/${userID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    authToken
  );

  return carts;
};

export const returnRequest = async (cartID, reason, authToken) => {
  return await apiFetch(
    `/return/${cartID}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reason),
    },
    authToken
  );
};

//To remove bc its old----
// export const requestDesigner = async (data, files) => {
//   const reqId = uuid();
//   let filePromises = [];
//   if (files.length > 0) {
//     filePromises = files.map(async (file) => {
//       const fileName = `${reqId}/${file.path}`;

//       let formData = new FormData();
//       formData.append('fileName', reqId);
//       const response = await apiFetch('/designers/presigned', {
//         method: 'POST',
//         body: formData,
//       });

//       let { url, fields, bucketKey } = response;

//       formData = new FormData();

//       for (let [key, value] of Object.entries(fields)) {
//         formData.append(key, value);
//       }

//       formData.append('key', bucketKey);
//       formData.append('file', file);

//       let uploadOptions = {
//         method: 'POST',
//         mode: 'cors',
//         body: formData,
//       };

//       await fetch(url, uploadOptions);
//       return fileName;
//     });
//   }

//   data.file_locations = await Promise.all(filePromises);

//   return await apiFetch('/designers/request', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   });
// };
