import React, { useEffect } from 'react';
import Layout from '../components/layout';

const RoadmapLoader = ({ }) => {
    useEffect(() => {
        window.location.href = `https://marketplace.gildform.com/hsn`
        // setTimeout(() => {
        //     navigate('/dashboard/#roadmap');
        // }, 4500);
    });

    return (
        <Layout className='loader'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3' />
                    <div className='col-sm-6'>
                        <div className='box-loader text-center'>
                            <div className='generate'>
                                Loading ...
                            </div>
                            <div className='loading'>
                                <div className='dot' />
                                <div className='dot' />
                                <div className='dot' />
                                <div className='dot' />
                                <div className='dot' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default RoadmapLoader;
