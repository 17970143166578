import {
  getUser,
  createUser as createUserApi,
  updateUser as updateUserApi,
  updateMembership,
  updateBilling as updateBillingAPI,
  safeGetAuthFromState,
  addNewCreditCard,
  getMarketplaceUser,
} from '../../utils/api';
import {
  Login as login,
  getUser as getUserAction,
  lastChecked,
  setShopifyAccessToken as setShopifyAccessTokenAPI,
  setShopifyUrl as setShopifyUrlAPI,
  editRequest,
  setUserProjects,
  setFirstTimeLogin,
} from './action';

export const Login = (auth0Response) => async (dispatch, getState) => {
  let user;
  let didExsist = true;
  try {
    user = await getUser(auth0Response.user.sub, auth0Response.token);
  } catch (e) {
    if (e.status === 404 || e.status === 400) {
      didExsist = false;
    }
  }
  console.log(`user ne LOGIN======`, user);
  const details = {
    auth: {
      ...auth0Response,
      accessToken: auth0Response.token,
    },
    profile: {
      ...auth0Response.user,
      ...user,
    },
  };
  dispatch(login(details));
  //return to false
  dispatch(setFirstTimeLogin(didExsist ? false : true));
  return didExsist;
};

export const createUser = (user) => async (dispatch, getState) => {
  const authToken = getState().login.auth.auth.accessToken;
  const userFromApi = await createUserApi(user, authToken);
  dispatch(setFirstTimeLogin(true));
  dispatch(getUserAction(userFromApi));
  dispatch(lastChecked());
};

export const updateUser = (user) => async (dispatch, getState) => {
  const authToken = getState().login.auth.auth.accessToken;
  const newUser = await updateUserApi(user, authToken);
  console.log(`newUser ne upDATE User`, newUser);
  dispatch(getUserAction(newUser));
  dispatch(lastChecked());
  return newUser;
};

export const changeMembership = (userID, membership) => async (
  dispatch,
  getState
) => {
  dispatch(editRequest());
  const authToken = getState().login.auth.auth.accessToken;
  const newUser = await updateMembership(userID, membership, authToken);
  console.log('newUser n changeMembership :>> ', newUser);
  dispatch(getUserAction(newUser));
  dispatch(lastChecked());
  return newUser;
};

export const retrieveUser = (userID) => async (dispatch, getState) => {
  const authToken = safeGetAuthFromState(getState());
  if (!authToken) {
    return;
  }
  dispatch(getUserAction(await getUser(userID, authToken)));
  dispatch(lastChecked());
};

export const updateBilling = (userID, newStripeToken) => async (
  dispatch,
  getState
) => {
  const authToken = getState().login.auth.auth.accessToken;
  const billing = await updateBillingAPI(userID, newStripeToken, authToken);
  console.log(`billing from update`, billing);
  dispatch(getUserAction(billing));
  dispatch(lastChecked());
  return billing;
};

export const addNewCard = (userID, newStripeToken) => async (
  dispatch,
  getState
) => {
  const authToken = getState().login.auth.auth.accessToken;
  const newCard = await addNewCreditCard(userID, newStripeToken, authToken);
  console.log(`newCard from update`, newCard);
  return newCard;
};

export const setShopifyAccessToken = (accessToken) => async (
  dispatch,
  getState
) => {
  dispatch(setShopifyAccessTokenAPI(accessToken));
};

export const setShopifyUrl = (url) => async (dispatch, getState) => {
  dispatch(setShopifyUrlAPI(url));
};

export const getMarketplaceUsersDesigns = (id) => async (
  dispatch,
  getState
) => {
  const res = await getMarketplaceUser(id);
  dispatch(setUserProjects(res));
  return res;
};
