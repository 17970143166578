import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
// import ck from 'creditkey-js';

import Cart from './CartContainer';
import CheckoutInfo from '../../utils/schemas/shippingSchema';
import ShoppingCartStep from './ShoppingCartStep';
import Payment from '../creditcard/CreditCardModal';
import Modal from '../../components/Modal';
import ShippingInfo, { ConvertProfileToIntial } from './ShippingInfo';
import {
  updateShippingInfo,
  shouldHideCart,
  shouldShowCart,
  setPaymentMethod,
  setCreditOrder,
  charging,
  charged,
} from '../../state/checkout/actions';
import {
  clearCart,
  setTax,
  setShippingOption,
  showOfferCart as showOfferCartAction,
  hideOfferCart as hideOfferCartAction,
  setOrderCheckoutSuccessful as setOrderCheckoutSuccessfulAction,
  setOffersPurchased as setOffersPurchasedAction,
  setInitialOffers as setInitialOffersAction,
} from '../../state/shoppingCart/actions';
import // addProductToCart as addProductToCartAction,
  '../../state/shoppingCart/thunks';
import { checkout } from '../../state/checkout/thunks';

import 'react-toastify/dist/ReactToastify.css';
import { addNewCard, updateBilling } from '../../state/login/thunks';
import CreditKey from '../creditcard/creditKey';
import {
  isProductChosen,
  openCreditCardCheckout,
} from '../../state/create/jewelry/actions';
import {
  checkoutCart,
  checkoutOfferCart,
  getPromotionCodes,
  getShippmentRates,
  getUserOffers,
  uploadLaserEngravingImages,
} from '../../utils/api';
import { isEmptyObj, assemblySubPrice } from '../../utils/helpers';
import noImage from '../../images/noimage.jpg';

const CREDIT_KEY = process.env.GATSBY_CREDIT_KEY;
const CREDIT_TOKEN = process.env.GATSBY_CREDIT_SECRET;
const CREDIT_KEY_URL = process.env.GATSBY_CREDIT_KEY_API_URL;

const FullCheckout = (props) => {
  const [stripe, setStripe] = useState(null);
  const [step, setStepState] = useState(0);
  const [payment, setPaymentOption] = useState('Default');
  const [shippingValues, setShippingValues] = useState(null);
  const [shipTo, setShipTo] = useState('shipToMe');

  const [isCardModalOpen, openCardModal] = useState(false);
  const [cardInfoLoad, setCardInfoLoad] = useState(false);
  const [cardErrors, setCardErrors] = useState(undefined);
  const [defaultCard, setDefaultCard] = useState(false);
  const [shippingRatePrice, setShippingRate] = useState(null);
  const [rateFetched, setFetchRates] = useState(1);

  const [shippoMethod, setShippoMethod] = useState(null);
  const [shippoId, setShippoId] = useState(null);
  const [shippoServiceToken, setShippoToken] = useState(null);
  const [shippoCarrier, setShippoCarrier] = useState(null);
  const [shippoEstimatedDelivery, setShippoEstimatedDelivery] = useState(null);

  const [shippingRates, setRates] = useState(null);

  const [promoCodes, setPromoCodes] = useState([]);
  const [promoCode, setPromoCode] = useState(null);

  const [offers, setOffers] = useState([]);
  const [timers, setTimers] = useState({});
  const [isTimeLimitReached, setTimeLimitReached] = useState({});
  const [isSubmittingOfferPayment, setIsSubmittingOfferPayment] = useState({
    id: null,
    isSubmitting: false,
  });

  const paymentOptions = ['Default', 'Credit-Key'];

  const [shouldShowCart, setState] = [
    props.shouldShowCart,
    (newState) => {
      if (newState === false) {
        return props.hideCart();
      }
      return props.showCart();
    },
  ];

  function matchPromoCode(givenCode, codes) {
    return codes.find((cd) => cd.code === givenCode.trim());
  }

  const fetchPromoCodes = async () => {
    const res = await getPromotionCodes();
    setPromoCodes(res.data);
  };

  const fetchCustomerOffers = async () => {
    const res = await getUserOffers(15);
    if (res) {
      const filteredOffers = res.settings.filter((offer) => {
        const customers = offer.customers ? JSON.parse(offer.customers) : [];
        const memberships = offer.membership
          ? JSON.parse(offer.membership)
          : [];

        if (offer.customers || offer.membership) {
          const isApplicableToCustomer =
            customers.length > 0
              ? customers.find(
                (customer) =>
                  customer.id === (props.profile && props.profile.erp_id)
              )
              : false;

          const isApplicableToMembership =
            memberships.length > 0
              ? memberships.find(
                (membership) =>
                  membership.name ===
                  (props.profile && props.profile.membership.name)
              )
              : false;

          return isApplicableToCustomer || isApplicableToMembership;
        } else {
          return true;
        }
      });

      const newArr = filteredOffers.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          membership: item.membership,
          image: item.type,
          price: item.value,
          stripe_payment_id: item.extra_info,
          time_limit: Number(item.code) * 60,
          customers: item.customers,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
        };
      });

      const newTimers = {};
      newArr.forEach((offer) => {
        newTimers[offer.id] = Number(offer.time_limit);
      });
      setTimers(newTimers);
      props.setInitialOffers(newArr);
      setOffers(newArr);
    }
  };

  useEffect(() => {
    if (Number(step) === 2) {
      fetchCustomerOffers();
    }
    return () => {
      Object.values(timers).forEach((timer) => clearInterval(timer));
    };
  }, [props.orderCheckoutSuccessful, step]);

  useEffect(() => {
    if (Number(step) === 3) {
      Object.keys(timers).forEach((offerId) => {
        if (Number(timers[offerId]) === 0 && !isTimeLimitReached[offerId]) {
          setTimeLimitReached((prevIsTimeLimitReached) => ({
            ...prevIsTimeLimitReached,
            [offerId]: true,
          }));
        }
      });
    }
  }, [timers, step]);

  useEffect(() => {
    if (!isEmptyObj(timers) && props.shouldShowOfferCart) {
      const timer = setInterval(() => {
        setTimers((prevTimers) => {
          const updatedTimers = { ...prevTimers };
          Object.keys(updatedTimers).forEach((offerId) => {
            updatedTimers[offerId] = Math.max(updatedTimers[offerId] - 1, 0);
          });
          return updatedTimers;
        });
      }, 1000);

      if (Object.values(timers).every((val) => Number(val) === 0)) {
        clearInterval(timer);
        return;
      }

      return () => {
        clearInterval(timer);
      };
    }
  });

  useEffect(() => {
    if (promoCodes.length === 0 && Number(step) === 1) {
      fetchPromoCodes();
    }
  }, [step]);

  useEffect(() => {
    if (props.checkout && Number(step) === 1) {
      if (props.checkout.isCharging) {
        props.setCharged();
      }
    }
  }, [props.checkout, step]);

  const matchedCode =
    promoCode &&
    matchPromoCode(
      promoCode,
      promoCodes.filter((cd) => cd.coupon.name.includes('Production'))
    );

  const collectCardInfo = async () => {
    setCardInfoLoad(true);
    setCardErrors(undefined);
    const stripeResponse = await stripe.stripe.createToken(stripe.card);
    if (stripeResponse.error) {
      setCardErrors(stripeResponse.error);
      return;
    }
    const tok = stripeResponse.token.id;
    await props.updateBilling(
      props.profile.subUser ? props.profile.admin_id : props.profile.id,
      tok
    );
    setCardInfoLoad(false);
    openCardModal(false);
    props.setOpenCreditCard(false);
  };

  const PaymentDetailsCard = (payment) => {
    switch (payment) {
      case 'Default':
        return props.profile && props.profile.cardInfo ? (
          <Payment
            onChange={props.updateBilling}
            billingInfo={props.checkout.billing}
            setStripe={setStripe}
            setDefaultCard={setDefaultCard}
            defaultCard={defaultCard}
            profile={props.profile}
            isOffer={true}
          />
        ) : (
          <>
            <Modal
              title='Enter Your Credit Card Information'
              show={isCardModalOpen}
              handleClose={() => {
                openCardModal(false);
                props.setOpenCreditCard(false);
              }}
              primaryButtons={[
                {
                  title: cardInfoLoad ? (
                    <i className='fas fa-circle-notch fa-spin' />
                  ) : (
                    'Save new card'
                  ),
                  handle: collectCardInfo,
                },
              ]}
              dangerButtons={[
                {
                  title: 'Cancel',
                  handle: () => {
                    openCardModal(false);
                    props.setOpenCreditCard(false);
                  },
                },
              ]}
            >
              <Payment
                setStripe={setStripe}
                defaultCard={defaultCard}
                setDefaultCard={setDefaultCard}
              />
              {cardErrors && <p>{cardErrors.message}</p>}
            </Modal>
            <div className='card-alert text-center'>
              Please enter your credit card information!
            </div>
            <div className='text-center mb-5'>
              <Button
                variant='link'
                className='card-button'
                onClick={() => {
                  openCardModal(true);
                  props.setOpenCreditCard(true);
                }}
              >
                Enter Credit Card info
              </Button>
            </div>
          </>
        );
      // case 'Credit-Key':
      //   return (
      //     <CreditKey
      //       cart={props.cart}
      //       salesTax={props.salesTax}
      //       shippingPrice={props.shipping.shippingMethodPrice || 0}
      //       profile={props.profile}
      //       checkout={props.checkout}
      //     />
      //   );
      default:
        return null;
    }
  };

  const cont = async (values, title) => {
    setStepState(step + 1);
    const data = {
      city: props.profile ? props.profile.address.city : '',
      address1: props.profile
        ? `${props.profile.address.address1} ${props.profile.address.address2
          ? `,${props.profile.address.address2}`
          : ''
        }`
        : '',
      state: props.profile ? props.profile.address.state : '',
      country: props.profile ? props.profile.address.country : '',
      zipcode: props.profile ? props.profile.address.zipcode : '',
      name: props.profile
        ? props.profile.firstName + ' ' + props.profile.lastName
        : '',
      items: props.items,
      phone: props.profile ? props.profile.phone : '',
    };
    if (title === 'Shopping Cart') {
      setFetchRates(0);
      const res = await getShippmentRates(data, props.apiToken);
      if (res.status === 'SUCCESS') {
        if (res.rates.length !== 0) {
          setFetchRates(1);
        }
        setRates(res.rates);
      } else {
        setFetchRates(2);
      }
    }
  };

  const back = () => setStepState(step - 1);

  const assemblySubItemsCartItems = props.cart.items.map((item) => {
    if (item.assemblySubItems) {
      const assemblyPrice = assemblySubPrice(item.assemblySubItems);
      return { ...item, assemblySubItemPrice: assemblyPrice };
    } else {
      return { ...item, assemblySubItemPrice: 0 };
    }
  });

  const finalPrice = assemblySubItemsCartItems.reduce(
    (acc, item) =>
      acc +
      Number(
        (Number(item.price.totalPrice) + Number(item.assemblySubItemPrice)) *
        item.quantity
      ) +
      Number(props.salesTax),
    0
  );

  const shipPrice = shippingRatePrice ? Number(shippingRatePrice) : 0;

  const finalTotalPrice = finalPrice + Number(shipPrice);

  const restrictionCoupon = matchedCode
    ? matchedCode.restrictions
      ? matchedCode.restrictions.minimum_amount
        ? true
        : false
      : false
    : false;

  const applyDis = restrictionCoupon
    ? matchedCode.restrictions.minimum_amount <= Number(finalTotalPrice)
    : false;

  const discountPrice = matchedCode
    ? matchedCode.coupon.percent_off
      ? (Number(matchedCode.coupon.percent_off) / 100) * finalTotalPrice
      : matchedCode.coupon.amount_off / 100
    : null;

  const finalDiscountedPrice = restrictionCoupon
    ? applyDis
      ? finalTotalPrice - Number(discountPrice)
      : finalTotalPrice
    : discountPrice
      ? finalTotalPrice - Number(discountPrice)
      : finalTotalPrice;

  const submitCreditKey = async () => {
    const addresses = shippingValues.address
      ? shippingValues.address.split(',')
      : '';

    const billingAddress = {
      first_name: shippingValues.firstName,
      last_name: shippingValues.lastName,
      company_name: 'Gildform',
      email: shippingValues.email,
      address1: addresses[0],
      address2: addresses[1],
      city: shippingValues.city,
      state: String(shippingValues.state),
      zip: shippingValues.zipcode,
      phone_number: props.profile.phone,
    };

    const shippingAddress = billingAddress;

    const charges = {
      total: finalPrice,
      shipping: shippingRatePrice ? Number(shippingRatePrice) : 0,
      tax: props.salesTax,
      discount_amount: 0,
      grand_total: finalDiscountedPrice,
    };

    const cartItems = [];

    for (const item of props.cart.items) {
      cartItems.push({
        merchant_id: props.cart.id,
        name: item.material.name + ' ' + item.itemType.name,
        price: item.price.totalPrice,
        quantity: item.quantity,
        sku: item.material.sku,
        color: JSON.stringify(item.material.color),
        size: item.surfaceArea,
      });
    }

    const remoteId = props.cart.id;
    const customerId = props.profile.erp_id;
    const returnUrl = `${window.location.origin}/order-confirmation/thankyou`;
    const cancelUrl = `${window.location.origin}/model`;
    const order_complete_url = `${window.location.origin
      }/order-confirmation/thankyou`;

    const url = `${CREDIT_KEY_URL}/begin_checkout?public_key=${CREDIT_KEY}&shared_secret=${CREDIT_TOKEN}`;

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        remote_id: remoteId,
        remote_customer_id: customerId,
        cart_items: cartItems,
        billing_address: billingAddress,
        shipping_address: shippingAddress,
        charges: charges,
        return_url: returnUrl,
        cancel_url: cancelUrl,
        order_complete_url: order_complete_url,
        merchant_data: {
          'contact-method': 'email',
        },
        mode: 'modal',
      }),
    });
    // console.log('res credit-key----- :>> ', res);

    const data = await res.json();
    const orderCredit = data.id;
    props.setCreditOrder(orderCredit);
    // ck.checkout(data.checkout_url, 'modal');
  };

  const submitPayment = async (useDefaultCard) => {
    props.setCharging();
    let newCard;
    if (!defaultCard || !props.profile.cardInfo) {
      const stripeResponse = await stripe.stripe.createToken(stripe.card);
      newCard = await props.addNewCard(props.profile.sub, stripeResponse);
    }

    let notes;
    props.cart.items.map((item) => {
      notes = `${item.description ? `${item.description} ` : ''}`;
    });

    const orderCreated = await checkoutCart({
      customer: props.profile.subUser
        ? props.profile.admin_id
        : props.profile.sub,
      tax: props.salesTax,
      notes: notes,
      shippingMethod: {
        id: shippoMethod,
        price: Number(shippingRatePrice),
      },
      address: props.checkout.shipping.address,
      billing_address: props.checkout.shipping.billing_address,
      shipTo: shipTo,
      orderItems: props.cart.items,
      apiToken: props.apiToken,
      cartId: props.cart.id,
      defaultCard: defaultCard,
      newCard,
      shippoOptions: {
        method: shippoMethod,
        rate: shippoId,
        carrier: shippoCarrier,
        serviceToken: shippoServiceToken,
        shippo: rateFetched === 1 ? true : false,
      },
      shipping: props.checkout.shipping,
      matchedCode: matchedCode,
    });

    console.log('orderCreated =============', orderCreated);
    let laserImagesUploaded;
    for (const createdOrderItem of orderCreated.orderItems) {
      for (const item of props.cart.items) {
        if (
          item.id === createdOrderItem.unitId &&
          item.laserImage &&
          item.laserImage.length > 0
        ) {
          const data = {
            userId: props.profile.sub,
            name: `${props.profile.firstName} ${props.profile.lastName}`,
            email: props.profile.email,
            image_text: null,
            image: null,
            type: 'Gildform',
          };
          laserImagesUploaded = await uploadLaserEngravingImages(
            item.laserImage,
            createdOrderItem.id,
            data
          );
        }
      }
    }
    if (orderCreated && orderCreated.error) {
      toast.error(
        orderCreated.error.raw
          ? orderCreated.error.raw.message
            ? `There was a problem with checkout: ${orderCreated.error.raw.message
            }`
            : 'There was a problem with checkout! Please try again!'
          : 'There was a problem with checkout! Please try again!'
      );
    } else {
      toast.success('Submitted your order, its now under review!', {
        className: 'bg-primary text-white',
      });
      // props.showCart();
      props.setOrderCheckoutSuccessful(true);
      props.showOfferCart();
      setShipTo('shipToMe');
      // setState(true)
      // setTimeout(() => {
      //   navigate('/order-confirmation/thankyou');
      // }, 1000);
      if (props.initialOffers && props.initialOffers.length > 0) {
        setStepState(step + 1);
      } else {
        setStepState(0);
      }
    }
    props.setCharged();
    props.clearCart();
    props.hideCart();
    props.isProductChosen(null);
  };

  const submitOfferProductPayment = async (product) => {
    let newCard;
    setIsSubmittingOfferPayment({ id: product.id, isSubmitting: true });
    if (!props.profile.cardInfo) {
      try {
        const stripeResponse = await stripe.stripe.createToken(stripe.card);
        newCard = await props.addNewCard(props.profile.sub, stripeResponse);
      } catch (err) {
        console.log('err :>> ', err);
      }
    }
    const res = await checkoutOfferCart({
      customer: props.profile.subUser
        ? props.profile.admin_id
        : props.profile.sub,
      apiToken: props.apiToken,
      defaultCard: true,
      newCard,
      product,
    });
    console.log('res :>> ', res);
    if (res.status === 200) {
      setIsSubmittingOfferPayment({ id: null, isSubmitting: false });
      const itemExists =
        props.purchasedOffers &&
        props.purchasedOffers.length > 0 &&
        props.purchasedOffers.find((item) => item.id === product.id);
      if (itemExists) {
        props.setOffersPurchased([...props.purchasedOffers]);
      } else {
        props.setOffersPurchased([...props.purchasedOffers, product]);
      }
      props.hideOfferCart();
      // props.setOrderCheckoutSuccessful(false);
      props.clearCart();
      navigate('/order-confirmation/thankyou');
      toast.success('Offer added!', {
        className: 'bg-primary text-white',
      });
    } else {
      toast.error('There was an issue with adding this offer!', {
        className: 'bg-primary text-white',
      });
    }
  };

  const Steps = [
    <ShoppingCartStep
      title='Shopping Cart'
      msg='Please review your order details. you can modify or remove any of the created orders at this time. '
      continue={cont}
      cart={props.cart}
      salesTax={props.salesTax}
      shippingPrice={shippingRatePrice}
      shippoEstimatedDelivery={shippoEstimatedDelivery}
      shipping={props.shipping}
      creditOrder={props.creditOrder}
      profile={props.profile}
      checkout={props.checkout}
      apiToken={props.apiToken}
      doCheckout={props.doCheckout}
      shippoMethod={shippoMethod}
      shippoId={shippoId}
      shippoCarrier={shippoCarrier}
      shippoServiceToken={shippoServiceToken}
      rateFetched={rateFetched}
      matchedCode={matchedCode}
      setCharged={props.setCharged}
      clearCart={props.clearCart}
      hideCart={props.hideCart}
      isProductChosen={props.isProductChosen}
      setStepState={setStepState}
    >
      <Cart selectedProduct={props.selectedProduct} />
    </ShoppingCartStep>,
    <ShoppingCartStep
      title='Shipping Information'
      msg='Please review your order details. you can modify or remove any of the created orders at this time. '
      continue={cont}
      cart={props.cart}
      next_title='Next'
      onBack={back}
      profile={props.profile}
      initialValues={ConvertProfileToIntial(props.profile)}
      schema={CheckoutInfo.shipping}
      salesTax={props.salesTax}
      shippingPrice={shippingRatePrice}
      shippoEstimatedDelivery={shippoEstimatedDelivery}
    >
      <ShippingInfo
        shippingInfo={props.checkout ? props.checkout.shipping : null}
        onChange={props.updateShippingInfo}
        updateSalesTax={props.setTax}
        setShippingValues={setShippingValues}
        setShippingRate={setShippingRate}
        setShippoMethod={setShippoMethod}
        shipTo={shipTo}
        setShipTo={setShipTo}
        cartItems={props.items}
        shippingRatePrice={shippingRatePrice}
        shippoEstimatedDelivery={shippoEstimatedDelivery}
        setShippoId={setShippoId}
        shippoId={shippoId}
        phone={props.profile ? props.profile.phone : ''}
        shippingRates={shippingRates}
        rateFetched={rateFetched}
        setFetchRates={setFetchRates}
        setRates={setRates}
        setShippoToken={setShippoToken}
        setShippoCarrier={setShippoCarrier}
        setShippoEstimatedDelivery={setShippoEstimatedDelivery}
      />
    </ShoppingCartStep>,
    <ShoppingCartStep
      title='Checkout'
      msg='Please review your order details. you can modify or remove any of the created orders at this time. '
      continue={(values) =>
        payment === 'Credit-Key'
          ? submitCreditKey()
          : submitPayment(values.useDefaultCard)
      }
      cart={props.cart}
      next_title='Checkout'
      onBack={back}
      isLoading={props.checkout.isCharging}
      profile={props.profile}
      salesTax={props.salesTax}
      shippingPrice={shippingRatePrice}
      shippoEstimatedDelivery={shippoEstimatedDelivery}
      paymentOptions={paymentOptions}
      setPaymentOption={setPaymentOption}
      payment={payment}
      validate={async (values) => {
        if (payment !== 'Credit-Key') {
          if (values.useDefaultCard) {
            return;
          }
          let errors = {};
          if (stripe === null) {
            errors.stripe = 'Something is screwy';
          }
          if (!defaultCard) {
            const tok = await stripe.stripe.createToken(stripe.card);
            if (tok.error) {
              errors.cardValidation = tok.error;
            }
            if (Object.keys(errors).length) {
              throw errors;
            }
          }
        }
      }}
      matchedCode={matchedCode}
      setPromoCode={setPromoCode}
      promoCode={promoCode}
      shippoMethod={shippoMethod}
      shippoId={shippoId}
      shippoCarrier={shippoCarrier}
      shippoServiceToken={shippoServiceToken}
      rateFetched={rateFetched}
      setCharged={props.setCharged}
      clearCart={props.clearCart}
      hideCart={props.hideCart}
      isProductChosen={props.isProductChosen}
      setStepState={setStepState}
    >
      {step === 2 && PaymentDetailsCard(payment)}
    </ShoppingCartStep>,
  ];

  if (!props.isAuthenticated) {
    return null;
  }

  const showCart = () => {
    if (props.count === 0) {
      return (
        <div className='cart-list'>
          <div>
            <Row className='title'>
              <Col>
                <h3>Looks like there is nothing in your cart</h3>
              </Col>
            </Row>
            <Row />
            <Row>
              <Col>
                <p>Upload an STL file below to start creating your piece</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Link
                  className='btn btn-primary checkout'
                  to='/model'
                  onClick={() => {
                    if (props.checkout) {
                      if (props.checkout.isCharging) {
                        props.setCharged();
                      }
                    }
                    props.hideCart();
                  }}
                >
                  Get Started
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return Steps[step];
  };

  const showOrHideOfferCart = (showCart) => {
    if (showCart) {
      props.showOfferCart();
    } else {
      props.hideOfferCart();
      props.clearCart();
      props.setOrderCheckoutSuccessful(false);
      setDefaultCard(false);
      // setOfferCartStep(1);
    }
  };

  const showOfferCart = () => {
    const buttonDisableTitleAndClassName = (item) => {
      const itemPurchased =
        props.purchasedOffers &&
        props.purchasedOffers.length > 0 &&
        props.purchasedOffers.find((prod) => prod.id === item.id);
      if (
        isSubmittingOfferPayment.id === item.id &&
        isSubmittingOfferPayment.isSubmitting
      ) {
        return {
          className: 'checkout',
          disabled: true,
          title: <i className='fas fa-circle-notch fa-spin' />,
        };
      } else {
        if (itemPurchased) {
          return { className: 'checkout', disabled: true, title: 'Added' };
        } else {
          return {
            className: 'checkout add-prod',
            disabled: false,
            title: '+ Add',
          };
        }
      }
    };

    return (
      step === 3 && (
        <div className='cart-list d-flex flex-column justify-content-between'>
          {/* {offerCartStep === 1 ? ( */}
          <>
            <div>
              <Row className='title'>
                <Col>
                  <h3>Check out some of our offers</h3>
                </Col>
              </Row>
              <Row />
              <div
                className='add-product-section'
                style={{ overflowY: 'scroll', height: '60vh', padding: '10px' }}
              >
                {props.initialOffers.map((prod, index) => (
                  <div
                    key={index}
                    className='product-container mt-5 mb-5'
                    style={{
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      padding: '20px 25px',
                      backgroundColor: isTimeLimitReached[prod.id]
                        ? '#f5f5f5'
                        : 'white',
                    }}
                    disabled={isTimeLimitReached[prod.id]}
                  >
                    <div className='d-flex justify-content-end pb-1 pt-1'>
                      {isTimeLimitReached[prod.id] ? (
                        <span style={{ color: '#D0342C', fontWeight: 'bold' }}>
                          Offer Expired
                        </span>
                      ) : (
                        <span style={{ color: '#D0342C' }}>
                          Expires in: {Math.floor(timers[prod.id] / 60)}:
                          {(timers[prod.id] % 60).toString().padStart(2, '0')}
                        </span>
                      )}
                    </div>
                    <div className='info-container d-flex justify-content-between'>
                      <div className='image-container w-25'>
                        {prod.image ? (
                          <a
                            href={prod.image}
                            target='__blank'
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              src={prod.image}
                              alt='product'
                              className='w-100 '
                            />
                          </a>
                        ) : (
                          <img src={noImage} alt='product' className='w-100 ' />
                        )}
                      </div>
                      <div className='text-container w-75 d-flex flex-column justify-content-start'>
                        <div
                          className='product-title pl-3 pr-3'
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                            marginBottom: '5px',
                          }}
                        >
                          {prod.name}
                        </div>
                        <div
                          className='product-description pr-3 pl-3'
                          style={{
                            marginTop: '5px',
                          }}
                        >
                          {prod.description}
                        </div>
                      </div>
                    </div>
                    <div className='price-container mt-1 mb-1 d-flex justify-content-between'>
                      <label>Price:</label>
                      <span className='value'>{`$${prod.price}`}</span>
                    </div>
                    <div className='button-container'>
                      <Button
                        className={
                          buttonDisableTitleAndClassName(prod).className
                        }
                        type='button'
                        disabled={
                          buttonDisableTitleAndClassName(prod).disabled ||
                          isTimeLimitReached[prod.id]
                        }
                        onClick={() => {
                          submitOfferProductPayment(prod);
                        }}
                      >
                        {buttonDisableTitleAndClassName(prod).title}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='checkout-buttons d-flex justify-content-between'>
              <Button
                className='checkout'
                type='button'
                onClick={() => {
                  props.hideOfferCart();
                  props.setOrderCheckoutSuccessful(false);
                  props.setInitialOffers([]);
                  navigate('/order-confirmation/thankyou');
                }}
              >
                No, I don't want these offers
              </Button>
            </div>
          </>
        </div>
      )
    );
  };

  const showCartCondition = shouldShowCart || props.shouldShowOfferCart;

  return (
    <li className={`nav-item cart ${showCartCondition && 'opened'}`}>
      <Link
        className='nav-link'
        to='/'
        onClick={(e) => {
          e.preventDefault();
          if (props.checkout.isCharging) return;
          if (props.orderCheckoutSuccessful) {
            showOrHideOfferCart(!props.shouldShowOfferCart);
          } else {
            setState(!shouldShowCart);
          }
          setStepState(0);
        }}
      >
        <i className='fas fa-shopping-cart' />
        {props.count > 0 && !shouldShowCart && (
          <span className='badge badge-secondary'>
            {props.count > 0 ? props.count : null}
          </span>
        )}
      </Link>
      {/* {shouldShowCart && showCart()} */}
      {shouldShowCart
        ? showCart()
        : props.shouldShowOfferCart && step === 3
          ? showOfferCart()
          : null}
    </li>
  );
};

const mapStateToProps = (state) => ({
  count: state.cart.items.length,
  items: state.cart.items,
  cart: state.cart,
  surfaceArea: state.create.jewelry.surfaceArea,
  checkout: state.checkout,
  shouldShowCart: state.checkout.showCart,
  shouldShowOfferCart: state.cart.showOfferCart,
  orderCheckoutSuccessful: state.cart.orderCheckoutSuccessful,
  // offerProductItems: state.cart.offerProductItems,
  purchasedOffers: state.cart.purchasedOffers,
  initialOffers: state.cart.initialOffers,
  profile: state.login.auth && state.login.auth.profile,
  apiToken: state.login.auth && state.login.auth.auth.token,
  salesTax: state.cart.salesTax || 0,
  shipping: state.checkout.shipping,
  creditOrder: state.checkout.creditOrder ? state.checkout.creditOrder : null,
  isAuthenticated: state.login.isAuthenticated,
  selectedProduct: state.create.jewelry && state.create.jewelry.selectedProduct,
});

const mapDispatchToProps = (dispatch) => ({
  updateShippingInfo: (field, value) =>
    dispatch(updateShippingInfo(field, value)),
  updateBilling: (field, value) => dispatch(updateBilling(field, value)),
  addNewCard: (field, value) => dispatch(addNewCard(field, value)),
  doCheckout: (data) => dispatch(checkout(data)),
  showCart: () => dispatch(shouldShowCart()),
  setCharging: () => dispatch(charging()),
  setCharged: () => dispatch(charged()),
  hideCart: () => dispatch(shouldHideCart()),
  clearCart: () => dispatch(clearCart()),
  showOfferCart: () => dispatch(showOfferCartAction()),
  hideOfferCart: () => dispatch(hideOfferCartAction()),
  setOrderCheckoutSuccessful: (data) =>
    dispatch(setOrderCheckoutSuccessfulAction(data)),
  setOffersPurchased: (data) => dispatch(setOffersPurchasedAction(data)),
  setInitialOffers: (data) => dispatch(setInitialOffersAction(data)),
  // addProductToCart: (data) => dispatch(addProductToCartAction(data)),
  setTax: (tax) => dispatch(setTax(tax)),
  setShippingOption: (option) => dispatch(setShippingOption(option)),
  setPaymentOption: (data) => dispatch(setPaymentMethod(data)),
  setCreditOrder: (data) => dispatch(setCreditOrder(data)),
  setOpenCreditCard: (data) => dispatch(openCreditCardCheckout(data)),
  isProductChosen: (data) => dispatch(isProductChosen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullCheckout);
