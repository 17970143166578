import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { toast } from 'react-toastify';
import shopifyIcon from '../../images/shopify-icon.png';
import gildformIcon from '../../images/gildformlogo-black.png';
import {
  uploadItemImage,
  uploadFile,
  uploadLaserEngravingImages,
  openConnectAccount,
  getSettingsById,
} from '../../utils/api';
import { goNext, goBack } from '../../state/steps/actions';
import {
  resetCreate,
  setAddedShopify,
  setFontLaser,
  setImageScreenShot,
  setLaserCustomText,
  setLaserImage,
  setMeshLocation,
  setQuantity,
} from '../../state/create/jewelry/actions';
import { resetPrice } from '../../state/create/price/actions';
import {
  assemblySubPrice,
  dynamoShopifyItem,
  getMeshId,
  getPermission,
} from '../../utils/helpers';
import CustomTooltip from '../Tooltip';
import { setUserGildformItem } from '../../state/login/action';
import {
  setProductDescription,
  setProductImages,
  setProductName,
  setProductPrice,
} from '../../state/create/gildformStore/action';
import { updateUser } from '../../state/login/thunks';
import GildformStore from '../GildformStore';
import Modal from '../Modal';

const API_URL = process.env.GATSBY_API_URL;
const APP_URL = process.env.GATSBY_APP_URL;
const CLIENT_ID = process.env.GATSBY_APP_STRIPE_CLIENT_ID;

const Step = ({
  stepNumber,
  title,
  isActive,
  children,
  displayPrevious,
  displaySubmit,
  goToPreviousStep,
  goToNextStep,
  isSkippable,
  displayNext,
  onSkip,
  onSubmit,
  verify,
  priceIsFetching,
  addToCartLoading,
  takeScreenShot,
  create,
  token,
  profile,
  setImageScreenShot,
  setMeshLocation,
  resetCreate,
  resetPrice,
  modelChosen,
  selected3dModel,
  selectedMarketplace3dModel,
  itemType,
  fileDimensionError,
  productionPage,
  weight,
  priceLoading,
  estimatedPrice,
  modelHeight,
  modelWidth,
  modelLength,
  showHowMuch,
  homMuchToMake,
  productChosen,
  selectedProduct,
  setLaserFont,
  setLaserCustomText,
  setLaserImage,
  isProductChosen,
  setSelectedProduct,
  totalProductPrice,
  laserCustomText,
  laserFont,
  laserImage,
  libraryProduct,
  prodName,
  prodDescription,
  prodPrice,
  prodImages,
  setProductName,
  setProductDescription,
  setProductPrice,
  setProductImages,
  setGildformItem,
  setOpenStoreModal,
  openStoreModal,
  gildformItem,
  update,
  currentStep,
  setRedirectedStripe,
  stripeComplete,
  stripeAcc,
  getStripeConnectAccount,
  setSelectedMarket3dModel,
  assemblySubItems,
  setAssemblySubItems,
  setSelectedModel,
  jewelry,
  setQuantityAct,
  setAddedShopify,
}) => {
  if (!onSkip) {
    onSkip = () => true;
  }
  if (!onSubmit) {
    onSubmit = () => true;
  }

  if (!isActive || !profile) {
    return null;
  }

  if (!verify) {
    verify = () => true;
  }

  const resetPage = () => {
    resetCreate();
    resetPrice();
    isProductChosen(null);
    setSelectedProduct(null);
    if (selectedMarketplace3dModel || selected3dModel) {
      setSelectedMarket3dModel(null);
      setSelectedModel(null);
    }
    if (gildformItem) {
      setGildformItem(null);
    }
    if (openStoreModal) {
      setOpenStoreModal(false);
    }
    if (assemblySubItems.length > 0) {
      setAssemblySubItems([]);
    }
    setProductDescription(null);
    setProductImages([]);
    setProductName(null);
    setProductPrice(null);
  };

  const [loading, setLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [openLoad, setOpenLoad] = useState(false);
  const [modal, setModal] = useState(false);

  const [disableAddToCart, setDisableAddToCart] = useState(false);
  const shopUrl = profile ? profile.shopifyUrl : null;
  const shopToken = profile ? profile.shopifyToken : null;
  const [accLink, setAccLink] = useState(null);
  const [gildformPercentage, setGildPercentage] = useState(null);

  const btnRef = useRef(null);
  const shopifybtnRef = useRef(null);
  const [proShow, setProfShow] = useState(false);
  const [showShopifyTool, setShowShopifyTool] = useState(false);

  const [imageUplod, setImgUpl] = useState(false);
  const [imageUse, setImgUse] = useState(false);

  const assemblySubItemsPrice = assemblySubPrice(assemblySubItems);

  const getSettingPercentage = async () => {
    const res = await getSettingsById(57);
    setGildPercentage(res);
  };

  const setModelElementsShop = async () => {
    let cartId = `${uuid()}-${uuid()}`;

    const modelLibrary = selected3dModel
      ? selected3dModel.mesh_folder
      : selectedMarketplace3dModel
        ? getMeshId(selectedMarketplace3dModel.model3D)
        : null;

    let id = modelChosen === true ? modelLibrary : cartId;

    const pic = productChosen ? null : await takeScreenShot();
    if (!productChosen) {
      setImageScreenShot(pic);
    }

    const data = !productChosen
      ? {
        id: id,
        file: window.meshes[create.jewelry.file_name],
      }
      : null;

    modelChosen === true || productChosen
      ? null
      : await uploadFile(data, token);

    const screenShotLocation = productChosen
      ? null
      : await uploadItemImage(id, pic);

    setMeshLocation(id);
    let laserImagesUploaded;
    if (laserImage && laserImage.length > 0) {
      laserImagesUploaded = await uploadLaserEngravingImages(
        laserImage,
        null,
        null
      );
    }
    return { laserImagesUploaded, id, screenShotLocation };
  };

  const addToShopify = async () => {
    setAddedShopify(true);
    setQuantityAct(1);
    setLoading(true);

    const modelElements = await setModelElementsShop();
    const randomID = Math.random()
      .toString(36)
      .slice(-6);

    const dataToDynamo = dynamoShopifyItem(
      modelElements.id,
      profile,
      create,
      laserFont,
      modelElements.laserImagesUploaded,
      laserCustomText,
      libraryProduct,
      productChosen,
      selectedProduct,
      totalProductPrice,
      shopUrl,
      modelElements.screenShotLocation,
      selectedMarketplace3dModel,
      assemblySubItems,
      selected3dModel,
      false,
      randomID
    );

    const priceTotal = productChosen
      ? totalProductPrice
      : Number(create.price.price.totalPrice) + Number(assemblySubItemsPrice);

    const cnd = selected3dModel || selectedMarketplace3dModel

    // 'https://7e3f-46-99-137-226.ngrok-free.app/gildform/api/add'
    const res = await fetch('https://apish.gildform.com/gildform/api/add', {
      body: JSON.stringify({
        product: {
          title: `${create.jewelry.material.name} ${create.jewelry.itemType.name
            }`,
          vendor: 'Gildform',
          handle: cnd ? `${modelElements.id}-${randomID}` : selectedProduct ? `${modelElements.id}-${randomID}prd`
            : `${modelElements.id}`,
          body_html: create.jewelry.description,
          tags: `${Number(priceTotal).toFixed(1)},${modelElements.id}${assemblySubItems.length > 0 ? `SUB` : ''
            },${create.jewelry.itemType.id}-${create.jewelry.material.id}-${create.jewelry.plating
              ? create.jewelry.plating.id
                ? create.jewelry.plating.id
                : null
              : null
            }-${create.jewelry.polish ? create.jewelry.polish.id : null}-${create.jewelry.assemblyOption
              ? create.jewelry.assemblyOption.id
              : null
            }-${create.jewelry.laserEngravingOption
              ? create.jewelry.laserEngravingOption.id
              : null
            }-${selectedMarketplace3dModel ? `mkpl` : null},${create.jewelry.volume
            }-${create.jewelry.surfaceArea},${create.jewelry.file_name},${productChosen ? `product` : null
            },${selectedProduct ? selectedProduct.id : null}`,
          images: [
            {
              src: productChosen
                ? selectedProduct && selectedProduct.prodImages[0]
                : modelElements.screenShotLocation.screenshot_url,
            },
          ],
          variants: [
            {
              price: Number(priceTotal).toFixed(1),
              title: `${create.jewelry.material.name}-${create.jewelry.itemType.name
                }`,
              sku: cnd ? `${modelElements.id}-${randomID}`
                : `${modelElements.id}`,
              option1: `${create.jewelry.material.name}-${create.jewelry.itemType.name
                }`,
            },
          ],
          added: true,
          published: false,
        },
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Shopify-URL': shopUrl,
        'Shopify-access-token': shopToken,
      },
    });
    setLoading(false);
    console.log('res ------', res);
    if (res.status === 200 || res.status === 201) {
      const resApi = await fetch(`${API_URL}/shopify/items`, {
        body: JSON.stringify(dataToDynamo),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success('Product added to Shopify successfully!', {
        className: 'bg-primary text-white',
        onClose: () => {
          resetPage();
        },
      });
      setModal(true);
    } else {
      toast.error('Something went wrong. Please check your Shopify details!');
    }
    setAddedShopify(false);
  };

  const permissions = profile && profile.subUser ? profile.permissions : [];

  const text = displaySubmit
    ? productionPage
      ? 'Show Me How Much Money I Could Make'
      : 'ADD TO CART'
    : 'CONTINUE';
  const action = displaySubmit
    ? productionPage
      ? homMuchToMake
      : onSubmit
    : goToNextStep;
  const disableSubUser = displaySubmit
    ? profile && profile.subUser
      ? !getPermission(permissions, 'order')
      : false
    : false;

  let btn = showHowMuch ? null : (
    <Button
      id='continue-tour'
      className='continue mr-2 mt-1'
      onClick={() => {
        if (productionPage || productChosen) {
          action();
        } else {
          if (verify()) {
            action();
          }
        }
      }}
      disabled={
        productionPage
          ? !modelHeight || !modelWidth || !modelLength || !weight || !itemType
          : productChosen
            ? null
            : !verify() ||
            disableAddToCart ||
            fileDimensionError ||
            disableSubUser
      }
      onMouseEnter={() => {
        if (displaySubmit && profile && profile.subUser) {
          if (!getPermission(permissions, 'order')) {
            setProfShow(true);
          }
        }
      }}
      onMouseLeave={() => {
        if (profile && profile.subUser) {
          setProfShow(false);
        }
      }}
      ref={btnRef}
    >
      {priceIsFetching || addToCartLoading ? (
        <i className='fas fa-circle-notch fa-spin' />
      ) : null}
      {priceLoading ? <i className='fas fa-circle-notch fa-spin' /> : text}
    </Button>
  );

  const link = accLink
    ? accLink
    : `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${`${APP_URL}/model`}&client_id=${CLIENT_ID}&state=${`Gildform123@`}`;

  const openStripeAcc = async (id) => {
    const res = await openConnectAccount(id);
    if (res) {
      setAccLink(res.url);
      if (!stripeComplete) {
        update({ ...profile, stripeConnectId: res.account_id });
      }
    }
  };

  useEffect(() => {
    if (currentStep >= 2) {
      if (profile && profile.stripeConnectId && !stripeAcc) {
        getStripeConnectAccount(profile.stripeConnectId);
      }
    }
    if (openStoreModal && profile) {
      if (!profile.stripeConnectId || (!stripeComplete && !accLink)) {
        openStripeAcc(profile.stripeConnectId);
      }
    }
  }, [openStoreModal, profile, currentStep]);

  useEffect(() => {
    if (openStoreModal) {
      getSettingPercentage();
    }
  }, [openStoreModal]);

  const publishToGildformStore = async () => {
    setStoreLoading(true);

    let finalgildCartID = gildformItem.cartId
      ? gildformItem.cartId.includes('***')
        ? gildformItem.cartId.split('***')
        : [gildformItem.cartId]
      : [gildformItem.cartId];
    const randomID = Math.random()
      .toString(36)
      .slice(-5);

    const finalProduct = {
      ...gildformItem,
      gildformStore: true,
      sellingPrice: prodPrice,
      itemName: prodName,
      itemDesc: prodDescription,
      itemImages: prodImages,
    };
    const res = await fetch(`https://apish.gildform.com/gildform/api/add`, {
      body: JSON.stringify({
        product: {
          title: `${prodName ? `${prodName}: ` : ''}${finalProduct.material} ${finalProduct.item
            }`,
          vendor: 'Gildform',
          handle: `${selectedMarketplace3dModel || selected3dModel
            ? `${finalgildCartID[0]}***${randomID}`
            : finalgildCartID[0]
            }`,
          body_html: prodPrice
            ? `${Number(prodPrice) + assemblySubItemsPrice
              ? Number(assemblySubItemsPrice).toFixed(2)
              : 0
            }-${prodDescription}`
            : gildformItem.create.jewelry.description,
          images: [
            {
              src: productChosen
                ? selectedProduct && selectedProduct.prodImages[0]
                : imageUplod
                  ? prodImages[0].image
                  : imageUse
                    ? gildformItem.image
                    : null,
            },
          ],
          variants: [
            {
              price:
                productChosen || (gildformItem && gildformItem.product)
                  ? Number(
                    currentStep === 0
                      ? gildformItem.price.totalPrice
                      : totalProductPrice
                  ).toFixed(2) + assemblySubItemsPrice
                    ? Number(assemblySubItemsPrice).toFixed(2)
                    : 0
                  : Number(gildformItem.create.price.price.totalPrice).toFixed(
                    2
                  ) + assemblySubItemsPrice
                    ? Number(assemblySubItemsPrice).toFixed(2)
                    : 0,
              title: `${prodName}:${finalProduct.material} ${finalProduct.item
                }`,
              sku: `${finalgildCartID[0]}`,
            },
          ],
          added: true,
          published: false,
        },
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Shopify-URL': 'https://gild-test-store.myshopify.com/',
        // 'Shopify-access-token': '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',
        'Shopify-URL': 'https://gildform-creator-shop.myshopify.com/',
        'Shopify-access-token': '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',
      },
    });
    setStoreLoading(false);
    if (res.status === 200 || res.status === 201) {
      const resApi = await fetch(`${API_URL}/shopify/gildform-store-item`, {
        body: JSON.stringify(finalProduct),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success('Product added to Gildform Store successfully!', {
        className: 'bg-primary text-white',
        onClose: () => {
          resetPage();
        },
      });
    } else {
      toast.error('Something went wrong!');
    }
  };

  const setGildformItemToState = async () => {
    const modelElements = await setModelElementsShop();
    const randomID = Math.random()
      .toString(36)
      .slice(-5);

    const dataToDynamo = dynamoShopifyItem(
      modelElements.id,
      profile,
      currentStep !== 0 ? create : gildformItem.create,
      laserFont,
      modelElements.laserImagesUploaded,
      laserCustomText,
      libraryProduct,
      productChosen,
      selectedProduct,
      currentStep !== 0 ? totalProductPrice : gildformItem.price.totalPrice,
      shopUrl,
      modelElements.screenShotLocation,
      selectedMarketplace3dModel,
      assemblySubItems,
      selected3dModel,
      false,
      randomID
    );
    setGildformItem({ ...dataToDynamo, create });
    setStoreLoading(false);
    setOpenLoad(false);
  };

  const saveGildformStoreItem = async () => {
    setStoreLoading(true);
    setRedirectedStripe(true);
    setTimeout(() => {
      window.open(link, '_self');
    }, 500);
  };

  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        setModal(false);
      }, 4000);
    }
  }, [modal]);

  if (displayPrevious && !estimatedPrice) {
    btn = (
      <>
        <Button
          variant='outline-primary'
          onClick={goToPreviousStep}
          style={{ marginRight: '15px' }}
        >
          BACK
        </Button>
        {btn}
      </>
    );
  }

  if (!displayNext && !productionPage) {
    btn = (
      <>
        {btn}
        {(shopUrl && shopUrl !== '') ||
          !!shopUrl ||
          (shopToken && shopToken !== '') ||
          !!shopToken ? (
          loading ? (
            <Button variant='outline-dark'>
              <i className='fas fa-circle-notch fa-spin' />
            </Button>
          ) : (
            <div className='d-flex flex-column'>
              <>
                <CustomTooltip
                  show={showShopifyTool}
                  profile={profile}
                  permissions={permissions}
                  action={'shopify'}
                  target={shopifybtnRef}
                  place='bottom'
                />
                {profile && profile.membership.name === 'Standard' ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id='tooltip-bottom'>
                        Only for Gold Plus memberships
                      </Tooltip>
                    }
                  >
                    <div>
                      <Button
                        onClick={() => {
                          addToShopify();
                          setDisableAddToCart(true);
                        }}
                        className='mt-1'
                        disabled={
                          (profile &&
                            profile.membership &&
                            profile.membership.name === 'Standard') ||
                            profile.subUser
                            ? !getPermission(permissions, 'shopify')
                            : false
                        }
                        onMouseEnter={() => {
                          if (profile && profile.subUser) {
                            if (!getPermission(permissions, 'shopify')) {
                              setShowShopifyTool(true);
                            }
                          }
                        }}
                        onMouseLeave={() => {
                          setShowShopifyTool(false);
                        }}
                      >
                        Publish on Shopify
                      </Button>
                      <div>
                        <span className='bg-white text-primary'>
                          Upgrade your membership to publish on shopify!
                        </span>
                      </div>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id='tooltip-bottom'>
                          Disclaimer: You don't need to publish more than a
                          single unit. The single unit can then be replicated in
                          your shopify store!
                        </Tooltip>
                      }
                    >
                      <div>
                        <Button
                          onClick={() => {
                            addToShopify();
                            setDisableAddToCart(true);
                          }}
                          className='mt-1'
                          disabled={
                            profile &&
                            profile.membership &&
                            profile.membership.name === 'Standard'
                            // ||
                            // assemblySubItems.length > 0
                          }
                          ref={shopifybtnRef}
                        >
                          <img
                            style={{
                              width: '22px',
                              height: '22px',
                              marginBottom: '5px',
                            }}
                            src={shopifyIcon}
                            alt='shopify-icon'
                          />
                          Publish on Shopify{' '}
                        </Button>
                      </div>
                    </OverlayTrigger>
                    {assemblySubItems.length > 0 && <span>Coming Soon</span>}
                  </>
                )}
              </>
            </div>
          )
        ) : (
          <>
            <Button
              className='mt-1'
              disabled={
                profile &&
                profile.membership &&
                profile.membership.name === 'Standard'
              }
            >
              <Link to={'/profile/edit'} className='text-white'>
                <div>Connnect to Shopify</div>
              </Link>
            </Button>
            <div>
              {profile &&
                profile.membership &&
                profile.membership.name === 'Standard' ? (
                <span className='bg-white text-primary'>
                  Upgrade your membership to connect to shopify!
                </span>
              ) : (
                ''
              )}
            </div>
          </>
        )}
        <Button
          style={{
            marginTop: '5px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onClick={async () => {
            setOpenLoad(true);
            setTimeout(() => {
              setOpenStoreModal(true);
            }, 800);
            await setGildformItemToState();
          }}
          disabled={
            (profile &&
              profile.membership &&
              profile.membership.name === 'Standard') ||
            disableAddToCart
          }
        >
          <img
            src={gildformIcon}
            alt='gildform'
            style={{ width: '20px', marginRight: '2px' }}
          />
          {openLoad ? (
            <span> ... Opening modal</span>
          ) : (
            'Sell on Gildform Store'
          )}
        </Button>
        <div style={{ marginTop: '10px' }}>
          <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
            Disclaimer:
          </span>{' '}
          You don't need to publish more than{' '}
          <span style={{ fontWeight: 'bold' }}>a single unit</span>. The single
          unit can then be replicated in your shopify store!
        </div>
      </>
    );
  }

  return (
    <Container
      className='d-flex flex-column justify-content-around step step-container-all'
      style={{ height: '100%' }}
    >
      <GildformStore
        openStoreModal={openStoreModal}
        setOpenStoreModal={setOpenStoreModal}
        stripeComplete={stripeComplete}
        prodName={prodName}
        prodImages={prodImages}
        prodPrice={prodPrice}
        saveGildformStoreItem={saveGildformStoreItem}
        publishToGildformStore={publishToGildformStore}
        gildformItem={gildformItem}
        setProductDescription={setProductDescription}
        setProductImages={setProductImages}
        setProductName={setProductName}
        setProductPrice={setProductPrice}
        gildformPercentage={gildformPercentage}
        storeLoading={storeLoading}
        setImgUpl={setImgUpl}
        setImgUse={setImgUse}
        imageUse={imageUse}
        imageUplod={imageUplod}
        openLoad={openLoad}
      />
      <CustomTooltip
        show={proShow}
        profile={profile}
        permissions={permissions}
        action={'order'}
        target={btnRef}
        place='bottom'
      />
      <Modal
        style={{ zIndex: 100000 }}
        title='Item added to your shopify store!'
        handleClose={resetPage}
        show={addToCartLoading === false && modal}
        primaryButtons={[
          {
            title: 'Add another Item',
            handle: resetPage,
          },
        ]}
        dangerButtons={[
          {
            title: 'Close',
            handle: () => {
              setModal(false);
            },
          },
        ]}
      >
        <p>
          Your {itemType && itemType.name} cast in{' '}
          {jewelry.material && jewelry.material.name} with specifications:
          <br />
          {jewelry.plating && jewelry.plating.name !== 'None'
            ? `Plating: ${jewelry.plating.name}, `
            : ''}
          {jewelry.polish ? `Polish: ${jewelry.polish.name}, ` : ''}
          {jewelry.assemblyOption
            ? `Assembly Option: ${jewelry.assemblyOption.name}, `
            : ''}
          {jewelry.laserEngravingOption
            ? `Laser Engraving: ${jewelry.laserEngravingOption.name}`
            : ''}
          <br />
          has been added to your shopify store!
        </p>
        <p>Would you like to add another item to your store?</p>
        <p>
          <img
            src={
              productChosen
                ? selectedProduct && selectedProduct.prodImages[0]
                : jewelry.screenshot
            }
            alt='Screenshot of your jewelry piece'
            width={200}
          />
        </p>
      </Modal>
      <Row className='sect'>
        <Col>
          <h3>
            <span>{stepNumber}</span>
            {title}
          </h3>
        </Col>
      </Row>
      <Row className='d-flex flex-column sect'>{children}</Row>
      <Row className='d-flex flex-row sect'>{btn}</Row>
      {isSkippable && (
        <Button
          variant='link'
          id='skip-my-tour-earrings'
          onClick={() => {
            onSkip();
            goToNextStep();
            if (Number(stepNumber) === 4) {
              setLaserImage(null);
              setLaserCustomText(null);
              setLaserFont(null);
            }
          }}
        >
          Skip This Step
        </Button>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  priceIsFetching: state.create.price.isFetching,
  material: state.create.jewelry.material,
  volume: state.create.jewelry.volume,
  addToCartLoading: state.cart.addToCartLoading,
  create: state.create,
  token: state.login.auth && state.login.auth.auth.token,
  profile: state.login.auth && state.login.auth.profile,
  itemType: state.create.jewelry.itemType,
  modelChosen: state.create.jewelry && state.create.jewelry.modelChosen,
  selected3dModel: state.create.jewelry && state.create.jewelry.selected3dModel,
  selectedMarketplace3dModel:
    state.create.jewelry && state.create.jewelry.selectedMarketplace3dModel,
  fileDimensionError:
    state.create.jewelry && state.create.jewelry.fileDimensionError,
  laserCustomText: state.create.jewelry.laserCustomText,
  laserFont: state.create.jewelry.laserFont,
  laserImage: state.create.jewelry.laserImage,
  libraryProduct: state.create.jewelry && state.create.jewelry.libraryProduct,
  selectedProduct: state.create.jewelry && state.create.jewelry.selectedProduct,
  prodName: state.create.gildformStore && state.create.gildformStore.name,
  prodDescription:
    state.create.gildformStore && state.create.gildformStore.description,
  prodPrice: state.create.gildformStore && state.create.gildformStore.price,
  prodImages: state.create.gildformStore && state.create.gildformStore.images,
  gildformItem: state.login.gildformItem && state.login.gildformItem,
  jewelry: state.create.jewelry,
});

const mapDispatchToProps = (dispatch) => ({
  setImageScreenShot: (data) => dispatch(setImageScreenShot(data)),
  setMeshLocation: (data) => dispatch(setMeshLocation(data)),
  goToNextStep: () => dispatch(goNext()),
  goToPreviousStep: () => dispatch(goBack()),
  resetCreate: () => dispatch(resetCreate()),
  resetPrice: () => dispatch(resetPrice()),
  setLaserFont: (data) => dispatch(setFontLaser(data)),
  setLaserCustomText: (data) => dispatch(setLaserCustomText(data)),
  setLaserImage: (data) => dispatch(setLaserImage(data)),
  update: (user) => dispatch(updateUser(user)),
  setProductName: (data) => dispatch(setProductName(data)),
  setProductDescription: (data) => dispatch(setProductDescription(data)),
  setProductPrice: (data) => dispatch(setProductPrice(data)),
  setProductImages: (data) => dispatch(setProductImages(data)),
  setGildformItem: (data) => dispatch(setUserGildformItem(data)),
  setQuantityAct: (quantity) => dispatch(setQuantity(quantity)),
  setAddedShopify: (data) => dispatch(setAddedShopify(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step);
