// const ck = require('creditkey-js');

export function findMilestonesQuestions(milestone, usersChecklist) {
  let nrOfQuestions = 0;
  milestone.question.map((item) => {
    usersChecklist.map((act) => {
      if (item.id === act.id && act.checked) {
        nrOfQuestions++;
      }
    });
  });
  if (nrOfQuestions === milestone.question.length) {
    return true;
  } else {
    return false;
  }
}

export function getClassName(activeTab) {
  if (activeTab === 'edit-profile' || activeTab === 'transactions') {
    return 'tab-active';
  } else {
    return 'tab-content';
  }
}

export function localTimeUS(itemDate) {
  let options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timezone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };
  let date = new Date(itemDate);
  let dateString = date.toLocaleString('en-US', options);
  return dateString;
}

export const getUserAnswers = (profile) => {
  let answers = profile.answers
    ? profile.answers.split('===').filter((item) => item)
    : [];

  let fullanswers = [];
  answers.map((answer) => {
    let element = answer.split(';');
    fullanswers.push({
      question: element[0].substring('Question: '.length),
      answer: element[1].substring('Answer: '.length),
      answerId: Number(element[2].substring('Ans-id: '.length)),
      ranking: element[3].substring('Ranking: '.length),
    });
  });
  return fullanswers;
};

const findItem = (id, testToggle) => {
  return testToggle.find((item) => item.id === id);
};

export const setOpenToggle = (obj, testToggle, setTestToggle) => {
  if (testToggle.length !== 0) {
    if (findItem(obj.id, testToggle)) {
      const newArr = testToggle.map((item) => {
        if (item.id === obj.id) {
          return {
            ...item,
            toggle: !item.toggle,
          };
        } else {
          return item;
        }
      });
      setTestToggle(newArr);
    } else {
      setTestToggle([...testToggle, obj]);
    }
  } else {
    setTestToggle([...testToggle, obj]);
  }
};

export const isToggleOpen = (index, testToggle) => {
  if (testToggle.length !== 0) {
    return testToggle.some(
      (toggleItem) => toggleItem.id === index && toggleItem.toggle
    );
  } else {
    return false;
  }
};

export const findIfFinished = (userQuestions, usersChecklist) => {
  let nrOfChecked = 0;
  userQuestions.map((item) => {
    if (item.checked) {
      nrOfChecked++;
    }
  });
  if (usersChecklist.length !== 0) {
    if (nrOfChecked === usersChecklist.length) {
      return true;
    }
  }
  return false;
};

export const getMeshId = (url) => {
  const splitted = url.includes('//') ? url.split('/') : [];
  return splitted[splitted.length - 2];
};

export function replaceMarketplaceState(location) {
  if (location.includes('market')) {
    if (window) {
      window.history.replaceState(
        {},
        document && document.title,
        '/model' + ''
      );
    }
  }
}

export function getPermission(permissions, action) {
  const enable = permissions
    ? permissions.some((prm) => {
      if (prm.toLowerCase().includes(action)) {
        return true;
      } else {
        return false;
      }
    })
    : false;

  return enable;
}

export function pigLatin(whatWeTitle) {
  var splitArray = whatWeTitle.split(/[-'\s]/);
  var finalString = '';
  for (let i = 0; i < splitArray.length; i++) {
    finalString += splitArray[i] === '&' ? '__' : splitArray[i] + '_';
  }
  return finalString;
}

export function filterTags(models) {
  let tags = [];
  models.length !== 0
    ? models.map((item) => {
      const parsedTags =
        item.tags && item.tags !== 'undefined' ? JSON.parse(item.tags) : [];
      parsedTags.map((tag) => {
        tags.push(tag.tag);
      });
    })
    : null;

  const uniqueTags =
    tags.length !== 0
      ? tags.filter((val, id, array) => {
        return array.indexOf(val) == id;
      })
      : [];
  return uniqueTags;
}

export function checkItemTag(parsedTags, tagsSelected) {
  return parsedTags.some((tag) => tagsSelected.includes(tag.tag));
}

export function dynamoShopifyItem(
  id,
  profile,
  create,
  laserFont,
  laserImagesUploaded,
  laserCustomText,
  libraryProduct,
  productChosen,
  selectedProduct,
  totalProductPrice,
  shopUrl,
  screenShotLocation,
  selectedMarketplace3dModel,
  assemblySubItems,
  selected3dModel,
  gildformItem,
  randomID
) {
  const cnd = selected3dModel || selectedMarketplace3dModel
  const dataToDynamo = {
    cartId: cnd
      ? `${id}-${randomID}` : selectedProduct ? `${id}-${randomID}prd`
        : id,
    mesh_folder: id,
    user: `${profile.firstName} ${profile.lastName}`,
    item: gildformItem ? create.jewelry.itemType.name : create.jewelry.itemType,
    material: create.jewelry.material
      ? gildformItem
        ? create.jewelry.material.name
        : create.jewelry.material
      : null,
    polish: create.jewelry.polish
      ? gildformItem
        ? create.jewelry.polish.name
        : create.jewelry.polish
      : null,
    plating: create.jewelry.plating
      ? gildformItem
        ? create.jewelry.plating.name
        : create.jewelry.plating
      : null,
    assemblyOption: create.jewelry.assemblyOption
      ? gildformItem
        ? create.jewelry.assemblyOption.name
        : create.jewelry.assemblyOption
      : null,
    laserEngravingOption: create.jewelry.laserEngravingOption
      ? gildformItem
        ? create.jewelry.laserEngravingOption.name
        : create.jewelry.laserEngravingOption
      : null,
    laserEngravingAttributes: {
      laserFont,
      laserImage: laserImagesUploaded ? laserImagesUploaded.data : [],
      laserCustomText,
      libraryProduct: libraryProduct ? libraryProduct.laserEngFile : null,
    },
    surfaceArea: productChosen ? null : create.jewelry.surfaceArea,
    volume: selectedProduct ? selectedProduct.weight : create.jewelry.volume,
    image: productChosen
      ? selectedProduct.prodImages[0]
      : screenShotLocation
        ? screenShotLocation.screenshot_url
        : null,
    price: productChosen
      ? { totalPrice: Number(totalProductPrice).toFixed(2) }
      : {
        castPrice: `${create.price.price.castPrice.labor_per_unit}-${create.price.price.castPrice.machine_labor_cost_per_unit}-${create.price.price.castPrice.market_pricing_per_gram}-${create.price.price.castPrice.percentage_of_casting_increase_for_sprues_and_trees}-${create.price.price.castPrice.percentage_of_markup_for_failure}-${create.price.price.castPrice.price_for_sprues_and_trees}-${create.price.price.castPrice.price_of_material_loss}-${create.price.price.castPrice.price_per_casting}-${create.price.price.castPrice.total_casting_price_per_unit}-${create.price.price.castPrice.total_material_and_machine_cost}-${create.price.price.castPrice.total_material_price}`,
        fixedCostPrice: `
          ${create.price.price.fixedCostPrice.previous_month_fixed_costs}-${create.price.price.fixedCostPrice.previous_month_unit_distribution}-${create.price.price.fixedCostPrice.sum_of_previous_month_fixed_cost_per_unit}-${create.price.price.fixedCostPrice.total_fixed_cost_to_be_added_to_3d_model}-${create.price.price.fixedCostPrice.added_fixed_cost_to_every_unit}-${create.price.price.fixedCostPrice.actual_fix_cost}-${create.price.price.fixedCostPrice.total_dynamic_overhead_price_per_unit}-${create.price.price.fixedCostPrice.total_overhead_price_per_unit}`,
        polishLaborPrice: `${create.price.price.polishLaborPrice.increase_to_add_if_below_min}-${create.price.price.polishLaborPrice.machine_labor_cost_per_unit}-${create.price.price.polishLaborPrice.machine_load_unload_labor}-${create.price.price.polishLaborPrice.min_surface_area}-${create.price.price.polishLaborPrice.percentage_of_markup_for_failure}-${create.price.price.polishLaborPrice.price_per_polished_unit}-${create.price.price.polishLaborPrice.total_polishing_labor_and_machine_price}-${create.price.price.polishLaborPrice.total_polishing_price}-${create.price.price.polishLaborPrice.total_polishing_price_per_unit}`,
        threeDPrintingPrice: `${create.price.price.threeDPrintingPrice.labor_per_unit}-${create.price.price.threeDPrintingPrice.machine_labor_cost_per_unit}-${create.price.price.threeDPrintingPrice.material_cost_per_ml}-${create.price.price.threeDPrintingPrice.material_price_per_unit}-${create.price.price.threeDPrintingPrice.model_volume_percentage_increase_for_3d_support}-${create.price.price.threeDPrintingPrice.percentage_markup_for_failiure}-${create.price.price.threeDPrintingPrice.price_per_3d_model_part}-${create.price.price.threeDPrintingPrice.total_3d_printing_price_per_unit}-${create.price.price.threeDPrintingPrice.total_material_and_machine_price}-${create.price.price.threeDPrintingPrice.total_material_for_3d_supports}`,
        platingPrice: create.price.price.platingPrice
          ? `${create.price.price.platingPrice.material_cost_per_mm2}-${create.price.price.platingPrice.material_price_per_plated_unit}-${create.price.price.platingPrice.plating_labor_price_per_mm2}-${create.price.price.platingPrice.total_plating_labor}-${create.price.price.platingPrice.total_plating_price}`
          : 0,
        assemblyOptionPrice: create.price.price.assemblyOptionPrice
          ? `${create.price.price.assemblyOptionPrice.assembly_type_cost}-${create.price.price.assemblyOptionPrice.labor_for_assembly_type}-${create.price.price.assemblyOptionPrice.total_assembly_price_and_labor}-${create.price.price.assemblyOptionPrice.markup_for_failure} - ${create.price.price.assemblyOptionPrice.total_assembly_price_per_unit}`
          : 0,
        laserEngravingOptionPrice: create.price.price
          .laserEngravingOptionPrice
          ? `${create.price.price.laserEngravingOptionPrice.laser_type_cost}-${create.price.price.laserEngravingOptionPrice.labor_for_laser_type}-${create.price.price.laserEngravingOptionPrice.markup_for_failure}-${create.price.price.laserEngravingOptionPrice.total_laser_price_and_labor}-${create.price.price.laserEngravingOptionPrice.total_laser_price_per_unit}`
          : 0,
        totalPrice: Number(create.price.price.totalPrice).toFixed(2),
      },
    file_name: productChosen ? null : create.jewelry.file_name,
    shopUrl: shopUrl ? shopUrl : null,
    variant: false,
    variant_id: null,
    product: productChosen ? true : false,
    productId: selectedProduct ? selectedProduct : null,
    marketFile: selectedMarketplace3dModel ? true : false,
    multiAssembly: assemblySubItems.length > 0,
    assemblySubItems: assemblySubItems,
    selected3dModel: selected3dModel ? true : false,
    createdAt: formatDateNew(new Date(), 'yyyy-MM-ddTHH:mm:ss'),
  };
  return dataToDynamo;
}

export function findQuestionAndAns(stateAnswers, qtId, ansId) {
  return (
    stateAnswers &&
    stateAnswers.length > 0 &&
    stateAnswers.some((st) => st.question === qtId && st.answer === ansId)
  );
}

export function assemblySubPrice(assemblySubItems) {
  let assemblySubTotal = 0;

  if (assemblySubItems && assemblySubItems.length > 0) {
    assemblySubItems.map((item) => {
      if (item) {
        if (item.quantity) {
          if (Number(item.quantity) > 0) {
            if (item.price) {
              assemblySubTotal +=
                Number(item ? (item.quantity ? item.quantity : 1) : 1) *
                Number(item.price.totalPrice);
            }
          } else {
            if (item.price) {
              assemblySubTotal += Number(item.price.totalPrice);
            }
          }
        } else {
          if (item.price) {
            assemblySubTotal += Number(item.price.totalPrice);
          }
        }
      }
    });
  }

  return assemblySubTotal;
}

export function formatDate(date, cnd) {
  if (cnd) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' ' +
      strTime
    );
  } else {
    const newDate = date.split('T')[0].split('-');
    const fullDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;

    const hourDate = date.split('T')[1].split(':');
    const fullHours = `${hourDate[0]}:${hourDate[1]}`;
    return `${fullDate}  ${fullHours}`;
  }
}

export function generateUUID() {
  let uuid = '',
    i,
    random;

  for (i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0;

    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }

    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
  }

  return uuid;
}

export function errMessageBrandforge(
  membership,
  setMessages,
  message,
  dd,
  setErrMsg
) {
  const mmb = membership.metadata
    ? membership.metadata.nickname
      ? membership.metadata.nickname
      : membership.name.trim()
    : membership.name.trim();
  if (mmb === 'Standard') {
    if (dd.length <= 10) {
      setMessages((prevState) => [
        ...prevState,
        {
          ai_text: `<div><bold>${dd.length}/10</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline'>here</b> to get unlimited access.</div>`,
          ...message,
        },
      ]);
    } else {
      setErrMsg(true);
      setMessages((prevState) => [
        ...prevState,
        {
          ai_text: `<div><bold>${dd.length}/10</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline'>here</b> to get unlimited access.</div>`,
          ...message,
        },
      ]);
    }
  } else if (
    mmb.includes('Silver Plus') ||
    mmb === 'Bronze' ||
    mmb === 'Silver'
  ) {
    if (dd.length < 20) {
      setMessages((prevState) => [
        ...prevState,
        {
          ...message,
          ai_text: `<div><bold>${dd.length}/20</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline'>here</b> to get unlimited access.</div>`,
        },
      ]);
    } else if (dd.length >= 20) {
      setErrMsg(true);
      setMessages((prevState) => [
        ...prevState,
        {
          ...message,
          ai_text: `<div><bold>${dd.length}/20</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline'>here</b> to get unlimited access.</div>`,
        },
      ]);
    }
  }
}

export function clickHandler(e, openMemberModal) {
  // Version supporting older browsers:
  let el = e.target;
  while (el && el !== e.currentTarget && el.tagName !== 'B') {
    el = el.parentNode;
  }
  if (el && el.tagName === 'B') {
    openMemberModal(true);
  }
  // Alternative for modern browsers:
  // const el = e.target.closest("B");
  // if (el && e.currentTarget.contains(el)) {
  //     this.setState(({clicks}) => ({clicks: clicks + 1}));
  // }
}

export function getNextHighestIndex(arr, number) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] >= number) {
      return i;
    }
  }
}

export function isEmptyObj(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}

var monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
var dayOfWeekNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

function nth(n) {
  return (
    [`${n}st`, `${n}nd`, `${n}rd`][
    (((((n < 0 ? -n : n) + 90) % 100) - 10) % 10) - 1
    ] || `${n}th`
  );
}

function twoDigitPad(num) {
  return num < 10 ? '0' + num : num;
}

export function formatDateNew(date, patternStr, ordinalDay) {
  if (!patternStr) {
    patternStr = 'M/d/yyyy';
  }

  if (date) {
    var day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      h = hour % 12,
      hh = twoDigitPad(h),
      HH = twoDigitPad(hour),
      mm = twoDigitPad(minute),
      ss = twoDigitPad(second),
      aaa = hour < 12 ? 'am' : 'pm',
      EEEE = dayOfWeekNames[date.getDay()],
      EEE = EEEE.substr(0, 3),
      dd = twoDigitPad(day),
      dth = nth(day),
      M = month + 1,
      MM = twoDigitPad(M),
      MMMM = monthNames[month],
      MMM = MMMM.substr(0, 3),
      yyyy = year + '',
      yy = yyyy.substr(2, 2);
    // checks to see if month name will be used
    patternStr = patternStr
      .replace('hh', hh)
      .replace('h', h)
      .replace('HH', HH)
      .replace('H', hour)
      .replace('mm', mm)
      .replace('m', minute)
      .replace('ss', ss)
      .replace('s', second)
      .replace('S', miliseconds)
      .replace('dd', dd)
      .replace('d', ordinalDay ? dth : day)

      .replace('EEEE', EEEE)
      .replace('EEE', EEE)
      .replace('yyyy', yyyy)
      .replace('yy', yy)
      .replace('aaa', aaa);
    if (patternStr.indexOf('MMM') > -1) {
      patternStr = patternStr.replace('MMMM', MMMM).replace('MMM', MMM);
    } else {
      patternStr = patternStr.replace('MM', MM).replace('M', M);
    }
  }

  return patternStr;
}

export function debounceCustom(fn, wait) {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => fn(...args), wait);
  };
}

export function setupCkClient() {
  // return new ck.Client(process.env.GATSBY_CREDIT_KEY, 'production');
}

